body {
    /* background-color: hsl(120, 29%, 97%); */
    /* background: rgb(73 95 111); */
}

.white-bg {
    background-color: #ffffff;
}

.page-heading {
    border-top: 0;
    padding: 0 10px 20px 10px;
}

.forum-post-container .media {
    margin: 10px 10px 10px 10px;
    padding: 20px 10px 20px 10px;
    border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
    float: left;
    margin-right: 20px;
    text-align: center;
    width: 110px;
}

.forum-avatar .img-circle {
    height: 48px;
    width: 48px;
}

.author-info {
    color: #676a6c;
    font-size: 11px;
    margin-top: 5px;
    text-align: center;
}

.forum-post-info {
    padding: 9px 12px 6px 12px;
    background: #f9f9f9;
    border: 1px solid #f1f1f1;
}

.media-body>.media {
    background: #f9f9f9;
    border-radius: 3px;
    border: 1px solid #f1f1f1;
}

.forum-post-container .media-body .photos {
    margin: 10px 0;
}

.forum-photo {
    max-width: 140px;
    border-radius: 3px;
}

.media-body>.media .forum-avatar {
    width: 70px;
    margin-right: 10px;
}

.media-body>.media .forum-avatar .img-circle {
    height: 38px;
    width: 38px;
}

.mid-icon {
    font-size: 66px;
}

.forum-item {
    margin: 20px 0;
    
   
}

.views-number {
    font-size: 24px;
    line-height: 18px;
    font-weight: 400;
}

.forum-container,
.forum-post-container {
    /* padding: 30px !important; */
}

.forum-item small {
    color: #676;
}

.new-story-text {
    /* background-color: rgb(239, 255, 239); */
    border-radius: 0.5rem!important;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid solid;
    border-width: 1px;
    padding:10px;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
}

.new-comment-text {
    /* background-color: rgb(239, 255, 239); */
    /* margin-left:1%; */
    border-radius: 0.5rem!important;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid solid;
    border-width: 1px;
    padding:10px;
    width:60%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
}

.comment {
    margin-left:1%;
}

.new-body-text {
    /* background-color: rgb(239, 255, 239); */
    border-radius: 0.5rem!important;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid solid;
    border-width: 1px;
    padding:10px;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
}

.card-body {
    background-color: #f6faf6;
    /* border-radius: 0.5rem!important; */
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid solid;
    border-width: 1px;
}

.forum-item .forum-sub-title {
    color: #676;
    
    margin-left: 20px;
}

.unapproved {
    border: 1px solid red;
}

.forum-title {
    margin: 15px 0 15px 0;
}

.forum-info {
    text-align: center;
}

.forum-desc {
    color: #999;
}

.forum-icon {
    float: left;
    width: 30px;
    margin-right: 20px;
    text-align: center;
}

a.forum-item-title {
    color: inherit;
    display: block;
    font-size: 18px;
    font-weight: 600;
}

a.forum-item-title:hover {
    color: inherit;
}

.forum-icon .fa {
    font-size: 30px;
    margin-top: 8px;
    color: #9b9b9b;
}

.forum-item.active .fa {
    color: #1ab394;
}

.forum-item.active a.forum-item-title {
    color: #1ab394;
}

@media (max-width: 992px) {
    .forum-info {
        margin: 15px 0 10px 0;
        /* Comment this is you want to show forum info in small devices */
        display: none;
    }
    .forum-desc {
        float: none !important;
    }
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

/* .ibox.collapsed .ibox-content {
    display: none;
} */

.ibox.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}

.ibox:after,
.ibox:before {
    display: table;
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 3px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 14px 15px 7px;
    min-height: 48px;
}

.ibox-content {
   
    color: inherit;
    padding: 0px 0px 0px 0px;
    /* border-color: #e7eaec;
    border-image: none;
    /* border-style: solid solid none; */
    /* border-width: 1px 0; */
}

.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}

.message-input {
    height: 90px !important;
}

.form-control,
.single-line {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
}

.text-navy {
    color: #1ab394;
}

.mid-icon {
    font-size: 66px !important;
}

.m-b-sm {
    margin-bottom: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.animate { animation: disappear 4.5s linear; }

.copyAlert { opacity: 0; }

@keyframes disappear {
  10%  { opacity: 0; }
  40%  { opacity: 1; }
  60%  { opacity: 1; }
  100% { opacity: 0; }
}